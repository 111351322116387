<script>
import { advancedTableData } from "./fakeData";

/**
 * Advanced-table component
 */
export default {
  page: {
  },
  components: {},
  data() {
    return {
      tableData: advancedTableData,

      title: "Advanced",
      items: [
        {
          text: "Tables",
        },
        {
          text: "Advanced",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 20, 50],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        {
          key: "id",
          sortable: true,
        },
        {
          key: "account_name",
          sortable: true,
        },
        {
          key: "advance_amount",
          sortable: true,
        },

        {
          key: "amount_collected",
          sortable: false,
        },
        {
          key: "due_date",
          sortable: false,
        },
        {
          key: "due_pending",
          sortable: false,
        },
      ],
      fields2: [
        {
          key: "id",
          sortable: true,
        },
        {
          key: "finance_name",
          sortable: true,
        },
        {
          key: "date",
          sortable: true,
        },

        {
          key: "amount",
          sortable: false,
        },
        {
          key: "payment_method",
          sortable: false,
        },
        {
          key: "finance_type",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  props:["dashboardFinances", "dashboardTenants"],
  middleware: "authentication",
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Due Tenants</h4>
          <div class="row mt-4">
            <div class="col-sm-12 col-md-6">

            </div>
          </div>
          <!-- Table -->
          <div class="table-responsive mb-0">
            <b-table
              :items="dashboardTenants.due_tenants"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
            >

            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="dashboardTenants.due_tenants.length"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
