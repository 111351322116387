<template>
  <Home>
    <div class="w-100">
      <div class="d-flex flex-column justify-content-start position-relative">
        <div>
          <div class="custom-header my-3">
            Financial Analytics
          </div>
          <Stat :dashboardFinances="dashboardFinances" />
          <DashboardFTable :dashboardFinances="dashboardFinances" />
        </div>
        <div>
          <div class="custom-header my-3">
            Tenant Analytics
          </div>
          <div>
            <StatTenant
              :dashboardFinances="dashboardFinances"
              :dashboardTenants="dashboardTenants"
            />
            <!-- <TopUnits /> -->
            <DashboardTable  :dashboardFinances="dashboardFinances" :dashboardTenants="dashboardTenants"/>
          </div>
        </div>
      </div>
    </div>
  </Home>
</template>
<script>
import Stat from "@/new-components/Stats/Stat.vue";
import StatTenant from "@/new-components/Stats/StatTenant.vue";

// import TopUnits from "@/new-components/top-units/TopUnits.vue";
import DashboardFTable from "@/new-components/DashboardFTable/DashboardFTable.vue";
import DashboardTable from "@/new-components/dashboard-table/DashboardTable.vue";
import Home from "@/new-views/Home/Home.vue";
import { getDashboardFinancial, getDashboardTenant } from "@/utils/apis.js";
export default {
  data() {
    return {
      loader: true,
      dashboardFinances: {},
      dashboardTenants: {},
    };
  },
  methods: {
    async useDashboardFinancial() {
      try {
        const result = await getDashboardFinancial();
        console.log("Finance", result);
        this.dashboardFinances = result.data;
      } catch (error) {
        console.log(error);
      }
    },
    async useDashboardTenant() {
      try {
        const result = await getDashboardTenant();
        console.log("Tenant", result);
        this.dashboardTenants = result.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.useDashboardFinancial();
    this.useDashboardTenant();
  },
  components: {
    Stat,
    // TopUnits,
    DashboardTable,
    Home,
    StatTenant,
    DashboardFTable,
  },
};
</script>
<style lang="scss" scoped>
.custom-header {
  font-size: 1.6rem;
}
</style>
